import it1 from '../../assets/images/it1.jpg'
import it2 from '../../assets/images/it2.jpg'
import it3 from '../../assets/images/it3.jpg'
import it4 from '../../assets/images/it4.jpg'
import it5 from '../../assets/images/it5.jpg'

export const TOPICS = [
  {
    id: '1',
    image: it1,
    title: {
      default: 'Sessions individuals de musicoteràpia infantil',
      ca: 'Sessions individuals de musicoteràpia infantil',
      en: 'Individual children\'s music therapy sessions'
    },
    href: '/individual-sessions',
  },
  {
    id: '2',
    image: it2,
    title: {
      default: 'Estimulació musical en família',
      ca: 'Estimulació musical en família',
      en: 'Family music stimulation'
    },
    href: '/music-stimulation',
  },
  {
    id: '3',
    image: it5,
    color: 'rgb(151 120 77 / 50%)',
    title: {
      default: 'Estimulació musical en escoles bressol',
      ca: 'Estimulació musical en escoles bressol',
      en: 'Musical stimulation in nursery schools'
    },
    href: '/nursery-schools',
  },
  {
    id: '4',
    image: it4,
    color: 'rgb(136 126 113 / 50%)',
    title: {
      default: 'Musicoteràpia preventiva durant l\'embaràs',
      ca: 'Musicoteràpia preventiva durant l\'embaràs',
      en: 'Preventive music therapy during pregnancy'
    },
    href: '/preventive-therapy',
  },
  // {
  //   id: '3',
  //   image: it1,
  //   title: {
  //     default: 'Tallers de musicoteràpia inclusiva',
  //     ca: 'Tallers de musicoteràpia inclusiva',
  //     en: 'Sessions structure'
  //   },
  //   href: '/sessions',
  // },
]