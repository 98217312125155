import React from 'react'
import Gallery from '../components/TopicGallery/Gallery'
import Layout from '../components/Layout'
import { TOPICS } from '../components/TopicGallery/queFaigList'


const HomeIndex = ({ location }) => {


  return (
    <Layout isMain={true} location={location} lang={'en'}>
      <section id="one">
        <header className="major">
          <h2>
            Who am I?
          </h2>
        </header>
        <p>
          My name is Sara Cillan Grau and I studied the degree of <strong>Early Childhood Education</strong> with music specialization at the UAB, Barcelona.
        </p>
        <p>
          After doing my internship and working for a while in Germany, I realized the impact that music had on children with a specific needs. That is why I decided to do the <strong>Master of Music Therapy</strong> at the ESMUC, Barcelona. In the educational field I have worked in several kindergartens and I have carried out several pedagogical training courses. In addition, I am trained and have been responsible for a multisensory classroom within a kindergarten.
        </p>
        <p>
          From the field of music therapy, once I finished the master's degree, I attended different music therapy courses related to autism, functional diversity and child music therapy. I have worked at Cromosuma Foundation, in Barcelona, with children with Down Syndrome for a few years; once this work was finished, I decided to do individual sessions at home with children with diverse needs; and, finally, I have set up a room in my own home, in Granollers, where I currently carry out the sessions.
        </p>
        <p>
          In addition, for years, I have been implementing <strong>musical early stimulation sessions with children from 0-3</strong> in Granollers and on occasion I have carried out <strong>inclusive workshops of family music</strong> in different populations.
        </p>
        <p>
          During 2024, I expanded my training in <strong>perinatal music therapy</strong> with the course "Focal Obstetric Music Therapy", taught by Gabriel F. Federico, a leading expert in the field of music therapy applied to pregnancy and early childhood.
        </p>
      </section>

      <section id="two">
        <h2>Music Therapy</h2>
        <p style={{ fontStyle: 'italic' }}>
          “Music therapy is the <strong>professional use of music</strong> and its elements as an intervention in medical, educational, and everyday environments with individuals, groups, families, or communities who seek to optimize their quality of life and improve their <strong>physical, social, communicative, emotional, intellectual, and spiritual health and wellbeing</strong>.”
          <span style={{ display: 'block', textAlign: 'right' }}>WFMT, 2011</span>
        </p>
        <p>
          The music therapy sessions will accompany the child in their integral development and will work on those aspects that are necessary (memory, attention, waiting, emotions, communication ...) while enjoying making music.
          <br />
          We foster a non-judgemental environment where the children are respected and valued. They are freely able to express their feelings through the medium of music, voice and movement.
        </p>
      </section>
      <section id="three">
        <h2>What do I do?</h2>
        <Gallery lang={'en'} topics={TOPICS} />
        <span>Personalized inclusive music therapy workshops are also available on demand.
          <br />For more information  <a href="mailto:musicoterapia@saracillan.com">contact me</a>.
        </span>
      </section>
    </Layout>
  )
}

export default HomeIndex
