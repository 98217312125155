import React from 'react'
import PropTypes from 'prop-types'
import GalleryItem from './GalleryItem'
import { TOPICS } from './topicsList'

const Gallery = ({ topics = TOPICS, lang }) => {
  return (
    <div>
      {topics && (
        <div className="row">
          {topics.map((topic) => {
            return (
              <GalleryItem
                key={topic.id}
                id={topic.id}
                image={topic.image}
                href={topic.href}
                title={topic.title}
                color={topic.color}
                lang={lang}
              />
            );
          })}
        </div>
      )}
    </div>
  )
}

Gallery.displayName = 'Gallery'
Gallery.propTypes = {
  topics: PropTypes.array,
}

export default Gallery
